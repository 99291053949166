<template>
  <div>
    <win-layer-pop class="common-bl-area">
      <component
        :is="commonComponent"
        v-if="commonComponent"
        :parent-info="commonPopParams"
        @close="commonClosePopup"
        @selectFunc="commonSelectFunc"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import trans from '@/api/rest/trans/trans'

export default {
  name: 'CommonBL',
  components: {
    'vgm-pop': () => import('@/pages/trans/popup/VgmPop'),
    'bl-error-check-pop': () => import('@/pages/trans/popup/BLErrorCheckPop'),
    'jp-clp-pop': () => import('@/pages/pdf/popup/JpClpPop'),
    'sha-mf-data-pop': () => import('@/pages/trans/popup/ShaMfDataPop'),
    'shipping-log-pop': () => import('@/pages/trans/popup/ShippingLogPop'),
    'indemnity-req-pop': () => import('@/pages/trans/popup/IndemnityReqPop'),
    'attorney-file-upload-pop': () => import('@/pages/trans/popup/AttorneyFileUploadPop'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: () => {
        return {
          action: '',
          bkgNo: '',
          srRno: '',
          blNo: '',
          vgmFlag: '',
          methCd: '',
          reqCatCd: '',
          originCntrList: []
        }
      }
    }
  },
  data () {
    return {
      commonComponent: null,
      commonPopParams: {},
      commonSelectFunc: null,
      regParams: {},
      page: ''
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.initPageFlag()
    this.doAction()
  },
  methods: {
    initPageFlag () {
      const parentInfo = this.$props.parentInfo
      if (this.$ekmtcCommon.isEmpty(parentInfo.blNo)) {
        this.page = 'SR'
      } else {
        this.page = 'BL'
      }
    },
    async initData () {
      this.initPageFlag()
      const parentInfo = this.$props.parentInfo

      if (this.page === 'SR') {
        const param = {
          srNo: parentInfo.srRno,
          bkgNo: parentInfo.bkgNo
        }

        await trans.fnSrBkgInfo(param).then(res => {
          const result = res.data

          if (result !== undefined) {
            this.regParams = result
          }
        })
      } else {
        const param = {
          blNo: parentInfo.blNo,
          bkgNo: parentInfo.bkgNo
        }

        await trans.fnBlBkgInfo(param).then(res => {
          const result = res.data

          if (result !== undefined) {
            this.regParams = result
          }
        })
      }
    },
    doAction () {
      const action = this.$props.parentInfo.action
      if (action === 'PBL' || action === 'NBL') {
        this.fnAddSrNumber(action)
      } else if (action === 'GOLIST') {
        this.goList()
      } else if (action === 'VGMPOP') {
        this.vgmPop()
      } else if (action === 'ERROR_CHECK') {
        this.fnErrorCheckPop()
      } else if (action === 'JPCLP') {
        this.jpClpPop()
      } else if (action === 'SHAMF') {
        this.fnSHAMFPop()
      } else if (action === 'LOG') {
        this.fnLogPop()
      } else if (action === 'TRD_SHIPPER') {
        this.fnTrdShipperPop()
      } else if (action === 'TRD_SHIPPER_FILE') {
        this.downloadTrdFile()
      } else if (action === 'POA_SHIPPER') {
        this.fnPoaShipperPop()
      } else if (action === 'TRD_POA_SHIPPER_FILE') {
        this.downloadTrdAndPoaFile()
      }
    },
    async vgmPop () {
      await this.initData()
      const flag = this.$props.parentInfo.vgmFlag
      const originCntrList = this.$props.parentInfo.originCntrList
      const regParams = this.regParams
      if (flag === 'SAVE') {
        if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.200'), useConfirmBtn: true })) {
          this.$emit('callback')
          return
        }
      } else {
        if (regParams.polPortCd === 'HKG' || regParams.polPortCd === 'HKM') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.223'))
          return
        }

        if (originCntrList.length === 0) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.224'))
          return
        }
      }

      const blNo = this.$props.parentInfo.srRno || this.$props.parentInfo.blNo

      this.commonPopParams = {
        blNo: blNo,
        type: this.page,
        methCd: this.$props.parentInfo.methCd || '',
        tareModifyYn: this.$props.parentInfo.tareModifyYn
      }

      this.commonSelectFunc = this.callback
      this.commonOpenPopup('vgm-pop')
    },
    jpClpPop () {
      const blNo = this.$props.parentInfo.srRno || this.$props.parentInfo.blNo

      this.commonPopParams = {
        items: [{
          blNo: blNo
        }]
      }

      this.commonSelectFunc = ''
      this.commonOpenPopup('jp-clp-pop')
    },
    fnSHAMFPop () {
      if (this.$props.parentInfo.originCntrList.length === 0 && this.$props.parentInfo.polShtmCd === '01') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200_P1.020'))
        return
      }

      this.initPageFlag()

      const blNo = this.$props.parentInfo.srRno || this.$props.parentInfo.blNo
      this.commonPopParams = {
        type: this.page,
        blNo: blNo,
        grsWt: this.$props.parentInfo.grsWt,
        pkgQty: this.$props.parentInfo.pkgQty,
        grsCbm: this.$props.parentInfo.grsCbm,
        path: this.$props.parentInfo.path,
        containerList: this.$props.parentInfo?.containerList,
        polCtrCd: this.$props.parentInfo?.polCtrCd
      }

      this.commonSelectFunc = this.callback
      this.commonOpenPopup('sha-mf-data-pop')
    },
    fnLogPop () {
      const blNo = this.$props.parentInfo.srRno || this.$props.parentInfo.blNo
      this.commonPopParams = {
        bkgNo: this.$props.parentInfo.bkgNo,
        blNo: blNo
      }

      this.commonSelectFunc = this.callback
      this.commonOpenPopup('shipping-log-pop')
    },
    fnTrdShipperPop () {
      const blNo = this.$props.parentInfo.srRno || this.$props.parentInfo.blNo
      this.commonPopParams = {
        bkgNo: this.$props.parentInfo.bkgNo,
        blNo: blNo,
        type: this.page,
        reqCatCd: this.$props.parentInfo.reqCatCd
      }

      this.commonSelectFunc = this.callback
      this.commonOpenPopup('indemnity-req-pop')
    },
    fnPoaShipperPop () {
      const blNo = this.$props.parentInfo.srRno || this.$props.parentInfo.blNo
      this.commonPopParams = {
        bkgNo: this.$props.parentInfo.bkgNo,
        blNo: blNo,
        type: this.page,
        reqCatCd: this.$props.parentInfo.reqCatCd
      }

      this.commonSelectFunc = this.callback
      this.commonOpenPopup('attorney-file-upload-pop')
    },
    callback (vo) {
      this.$emit('callback', vo)
    },
    async downloadTrdFile () {
      let trdFileInfo = this.$props.parentInfo.trdFileInfo
      let blNo = this.$props.parentInfo.srRno || this.$props.parentInfo.blNo

      if (this.$ekmtcCommon.isEmpty(blNo)) {
        blNo = this.$props.parentInfo.bkgNo
      }

      let succFlag = true
      if (this.$ekmtcCommon.isEmpty(trdFileInfo) || this.$ekmtcCommon.isEmpty(trdFileInfo.reqRno)) {
        await trans.findTrdFileInfo(blNo).then(res => {
          if (res.headers.respcode !== 'C0000') {
            succFlag = false
          } else {
            trdFileInfo = res.data
          }
        })
      }

      if (!succFlag) {
        this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
        return
      }

      const params = {
        reqRno: trdFileInfo.reqRno,
        reqCatCd: trdFileInfo.reqCatCd,
        reqSeq: trdFileInfo.reqSeq,
        fileSeq: trdFileInfo.fileSeq,
        type: 'BL_ATTACH_LOI_UPLOAD'
      }

      await trans.fileDownload(params).then(response => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response.data, trdFileInfo.fileNm)
        } else {
          let blob = new Blob([response.data], { type: response.headers['content-type'] })

          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')

          link.href = url
          link.setAttribute('download', trdFileInfo.fileNm)
          link.click()
          link.remove()

          window.URL.revokeObjectURL(url)
        }
      }).catch(() => {
        this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
      })
    },
    async downloadTrdAndPoaFile () {
      let reqCatCd = this.$props.parentInfo.reqCatCd
      let fileInfo = reqCatCd === '22' ? this.$props.parentInfo.trdFileInfo : this.$props.parentInfo.poaFileInfo
      let bkgNo = this.$props.parentInfo.bkgNo

      let succFlag = true
      if (this.$ekmtcCommon.isEmpty(fileInfo) || this.$ekmtcCommon.isEmpty(fileInfo.reqRno)) {
        await trans.findTrdPoaFileInfo(bkgNo, reqCatCd).then(res => {
          if (res.headers.respcode !== 'C0000') {
            succFlag = false
          } else {
            fileInfo = res.data
          }
        })
      }

      if (!succFlag) {
        this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
        return
      }

      const params = {
        reqRno: fileInfo.reqRno,
        type: 'BK_ATTACH_LOI_POA_UPLOAD'
      }

      await trans.fileDownload(params).then(response => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response.data, fileInfo.fileNm)
        } else {
          let blob = new Blob([response.data], { type: response.headers['content-type'] })

          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')

          link.href = url
          link.setAttribute('download', fileInfo.fileNm)
          link.click()
          link.remove()

          window.URL.revokeObjectURL(url)
        }
      }).catch((e) => {
        console.log(e)
        this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
      })
    },
    async fnAddSrNumber (type) {
      await this.initData()
      const regParams = this.regParams
      const saveFlag = this.$props.parentInfo.saveFlag
      const blNo = this.$props.parentInfo.srRno || this.$props.parentInfo.blNo
      const bkgNo = this.$props.parentInfo.bkgNo
      const routeName = this.$route.name

      if (saveFlag !== 'SR_EDIT' && saveFlag !== 'BL_EDIT') {
        if (type === 'PBL') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.170'))
          return
        }
      }

      let isOk = true
      if (regParams.podShaYn === 'Y') {
        const params = {
          flag: this.page,
          blNo: blNo,
          bkgNo: bkgNo
        }

        let succFlag = true
        let result = null
        await trans.findSndShaYn(params).then(res => {
          if (res.headers.respcode !== 'C0000') {
            succFlag = false
          } else {
            result = res.data
          }
        }).catch(() => {
          succFlag = false
        }).finally(() => {
          if (succFlag && result !== null) {
            if (result === 'Y') {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200_P1.023'))
              isOk = false
            }
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
            isOk = false
          }
        })
      }

      if (!isOk) {
        return
      }

      if (regParams.cllYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('art.CSBK005'))
        return
      }

      if (type === 'PBL') {
        if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK365'), useConfirmBtn: true })) {
          return
        }

        let succFlag = true
        let nextBlNo = ''
        trans.getNextPartBlNo(blNo).then(res => {
          if (res.headers.respcode !== 'C0000') {
            succFlag = false
          } else {
            nextBlNo = res.data
          }
        }).catch(() => {
          succFlag = false
        }).finally(() => {
          if (succFlag) {
            if (this.$ekmtcCommon.isNotEmpty(nextBlNo) && nextBlNo.substr(nextBlNo.length - 1, 1) === '[') {
              this.$ekmtcCommon.alertDefault(this.$t('art.CMATK366'))
              return
            }

            if (routeName !== 'progress') {
              this.$emit('callback', nextBlNo)
            } else {
              this.$router.push({ name: 'bl', params: { newSrRno: nextBlNo, bkgNo: bkgNo } })
            }
          } else {
            this.$ekmtcCommon.alertDefault('msg.CSBL200.215')
          }
        })
      } else {
        if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK368'), useConfirmBtn: true })) {
          return
        }
        const params = {
          polPortCd: regParams.polPortCd,
          porPlcCd: regParams.porPlcCd
        }

        let succFlag = true
        let nextBlNo = ''
        trans.fnGetSrNoTemp(params).then(res => {
          if (res.headers.respcode !== 'C0000') {
            succFlag = false
          } else {
            nextBlNo = res.data
          }
        }).catch(() => {
          succFlag = false
        }).finally(() => {
          if (succFlag) {
            if (routeName !== 'progress') {
              this.$emit('callback', nextBlNo)
            } else {
              this.$router.push({ name: 'bl', params: { newSrRno: nextBlNo, bkgNo: bkgNo } })
            }
          } else {
            this.$ekmtcCommon.alertDefault('msg.CSBL200.216')
          }
        })
      }
    },
    fnErrorCheckPop () {
      this.initPageFlag()
      const blNo = this.$props.parentInfo.srRno || this.$props.parentInfo.blNo
      this.commonPopParams = {
        blNo: blNo,
        kindCd: this.page,
        catCd: '01'
      }

      this.commonSelectFunc = ''
      this.commonOpenPopup('bl-error-check-pop')
    },
    goList () {
      this.$router.push({ name: 'progress' }).catch(() => {})
    },
    commonOpenPopup (compNm) {
      this.commonComponent = compNm
      this.$ekmtcCommon.layerOpen('.common-bl-area')
    },
    commonClosePopup () {
      this.commonComponent = null
      this.commonSelectFunc = null
      this.$ekmtcCommon.layerClose('.common-bl-area')
    }
  }
}
</script>
